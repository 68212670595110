import React from 'react';
import ReactDOM from 'react-dom/client';
import loadTheme from 'theme';
import { Main as App } from 'component/app';
import reportWebVitals from 'utils/reportWebVitals';

import { setUseWhatChange } from '@simbathesailor/use-what-changed';
setUseWhatChange(process.env.NODE_ENV === 'development');

loadTheme();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Panel } from 'component/common';

const Component = () => {
    return (
        <Panel header="Unknown mode">
            Read README.md and set REACT_APP_MODE with a valid mode
        </Panel>
    );
};

export default Component;

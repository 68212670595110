import { Navbar } from 'component/common';

import { Main as Nav } from 'component/nav';

import config from 'utils/config';

const HeaderDefault = () => (
    <header>
        <Navbar pages={Nav} />
    </header>
);

const Component = () => {
    switch (config.mode) {
        default:
            return <HeaderDefault />;
    }
};

export default Component;

import React from 'react';

import S from 'theme/structure';

const { Row } = S.Form;

const FormRow = ({ children, label, error }) => {
    const childId = React.Children.toArray(children)[0]?.props?.id;

    return (
        <Row.Container subclasses={[childId]}>
            <Row.Content.Container subclasses={[childId]}>
                <Row.Content.Label subclasses={[childId]} htmlFor={childId}>
                    {label}
                </Row.Content.Label>
                {children}
                <Row.Content.Feedback subclasses={[childId]} type="invalid">
                    {error}
                </Row.Content.Feedback>
            </Row.Content.Container>
        </Row.Container>
    );
};

export default FormRow;

import S from 'theme/structure';

const {
    NavBar: { Container, Item },
} = S;

const Component = ({ pages = [] }) => (
    <Container>
        {pages.map(({ name, path }) => {
            const modifiers = [name.toLowerCase().replaceAll(' ', '_')];
            return (
                <Item.Container modifiers={modifiers} key={name}>
                    <Item.Link modifiers={modifiers} to={path}>
                        {name}
                    </Item.Link>
                </Item.Container>
            );
        })}
    </Container>
);

export default Component;

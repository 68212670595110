// Website mode
const MODES = {
    dev: 'dev',
};

let mode = (process.env.REACT_APP_MODE ?? '').toLowerCase();
if (!MODES[mode]) mode = MODES.dev;

const config = {
    mode,
    MODES,
};

export default config;

import React, {
    useState,
    createContext,
    useEffect,
    useContext as useReactContext,
} from 'react';

import { useWebPubSub } from 'component/hook';
import { SAML } from 'component/api';

import * as Utils from './Utils';

export const Context = createContext();

export const Provider = ({ children }) => {
    const [samlRequest, setSamlRequest] = useState(null);

    const [error, setError] = useState(null);
    const [apiResult, setApiResult] = useState(null);

    const loadings = {
        samlLogin: useState(false),
    };

    useEffect(() => {
        const parameters = new URLSearchParams(window.location.search);
        const r = parameters.get('saml_request_id');

        if (r && !samlRequest) {
            setSamlRequest(r);
        }
    }, [samlRequest]);

    const op = Utils.operationCaller(setError, setApiResult);

    const operations = {
        samlLogin: ({ accessToken }) =>
            op({
                state: loadings['samlLogin'],
                operation: () => SAML.login({ samlRequest, accessToken }),
                callbacks: {
                    200: ({
                        value: { entityEndpoint, SAMLResponse, RelayState },
                    }) => {
                        SAML.postSAMLResponse({
                            entityEndpoint,
                            SAMLResponse,
                            RelayState,
                        });
                    },
                },
            }),
    };
    return (
        <Context.Provider
            value={{
                samlRequest,
                samlError: error,
                samlApiResult: apiResult,
                ...Utils.operationsToJSON(operations),
                ...Utils.loadingsToJSON(loadings),
                SAMLWebPubSubHook: {
                    RequestAuth: useWebPubSub({
                        hookId: 'RequestAuth',
                        operation: (args) =>
                            SAML.requestAuth({ ...args, samlRequest }),
                        triggerText: 'Authenticate',
                        validation: ({ identifier }) => {
                            if (!identifier) {
                                return 'Identifier is required';
                            }
                            return undefined;
                        },
                        actions: {
                            'unified-identity': {
                                reverse_authn: {
                                    final: true,
                                    trigger: ({ access_token }) =>
                                        operations.samlLogin({
                                            accessToken: access_token,
                                        }),
                                },
                            },
                        },
                    }),
                },
            }}
        >
            {children}
        </Context.Provider>
    );
};

export const useContext = () => {
    const context = useReactContext(Context);
    if (!context) {
        throw new Error('useContext should be used inside Provider');
    }
    return context;
};

import { Container, Row, Col } from 'react-bootstrap';
import { Panel, Account, SAML } from 'component/common';
import { Main as Provider } from 'component/context';

const Component = () => {
    const {
        user,
        samlError,
        samlRequest,
    } = Provider.useContext();

    const renderContent = () => {
        if (user) return <Account.Profile />;

        if (!samlRequest) return <div>IdP Initiated flow is not supported</div>;

        if (!samlRequest && samlError)
            return <div>{JSON.stringify(samlError.error)}</div>;

        if (samlRequest)
            return (
                <>
                    <SAML.Susi />
                    {samlError && <div>{JSON.stringify(samlError.error)}</div>}
                </>
            );

        return null;
    };

    return (
        <Container>
            <Row>
                <Col>
                    <Panel header="Account">{renderContent()}</Panel>
                </Col>
            </Row>
        </Container>
    );
};

export default Component;

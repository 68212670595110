import React from 'react';

export class BEM {
    #prefix;

    constructor(prefix) {
        this.#prefix = prefix;
    }

    child(element) {
        return new BEM(`${this.#prefix}__${element}`);
    }

    bem() {
        return { className: this.#prefix };
    }

    component =
        (Component) =>
        ({ className, children, modifiers, subclasses, ...props }) => {
            const cs = [this.#prefix];
            if (subclasses) {
                cs.push([this.#prefix, ...subclasses].join('__'));
            }
            if (modifiers) {
                [...cs].forEach((c) => {
                    cs.push([c, ...modifiers].join('--'));
                });
            }

            return (
                <Component
                    {...props}
                    className={[...cs, className].filter(Boolean).join(' ')}
                >
                    {children}
                </Component>
            );
        };

    structure = (config) => {
        const processComponent = (bem, component) => {
            if (
                typeof component === 'string' ||
                (typeof component === 'object' && component['$$typeof'])
            ) {
                return bem.component(component);
            } else if (typeof component === 'object') {
                return Object.entries(component).reduce((acc, [key, value]) => {
                    acc[key.startsWith('$') ? key.substring(1) : key] =
                        processComponent(
                            key.startsWith('$')
                                ? bem
                                : bem.child(key.toLowerCase()),
                            value
                        );
                    return acc;
                }, {});
            }
            return component;
        };

        return processComponent(this, config);
    };

    toString() {
        return this.#prefix;
    }
}

import { useEffect } from 'react';

import { Divider, TODO } from 'component/common';
import { Main as Provider } from 'component/context';

import { useGoogleLoginButton } from 'component/hook/useGoogleLoginButton';
import S from 'theme/structure';

const { Modal } = S;

const ContentStructure = {
    main: {
        title: 'Log in or register',
        subtitle:
            'Your gateway to extraordinary destinations around the world.',
        elements: ['credentials', 'divider', 'google'],
    },
    openID4VC: {
        title: 'Continue with Neoke',
        subtitle: 'Use the Neoke mobile app for a passwordless log in.',
        elements: ['credentials'],
    },
    google: {
        title: 'Continue with Google',
        subtitle: 'Use your Google account to log in.',
        elements: ['google'],
    },
};

const Section = ({ name, children }) => (
    <Modal.Body.Section.Container subclasses={[name]}>
        {children}
    </Modal.Body.Section.Container>
);

const Component = ({ show, setShow }) => {
    const { OpenID4VCHook } = Provider.useContext();

    useEffect(() => {
        if (!show) OpenID4VCHook.Login.abort();
    }, [show, OpenID4VCHook.Login]);

    const handleClose = () => setShow(false);

    const { control: googleButton, triggered: googleTriggered } =
        useGoogleLoginButton();

    let page = 'main';
    if (OpenID4VCHook.Login.triggered) page = 'openID4VC';
    if (googleTriggered) page = 'google';

    const handleBack = () => {
        if (page === 'openID4VC') {
            OpenID4VCHook.Login.abort();
        }
    };

    const elements = {
        credentials: (
            <Section key="credentials" name="credentials">
                {OpenID4VCHook.Login.control}
            </Section>
        ),
        google: (
            <Section key="google" name="google">
                {googleButton}
                <TODO t="Deactivate google login button" />
            </Section>
        ),
        divider: <Divider key="or">OR</Divider>,
    };

    return (
        <Modal.Container
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header.Container closeButton>
                <Modal.Header.Title.Container>
                    <Modal.Header.Title.Back
                        onClick={handleBack}
                        modifiers={page === 'main' ? ['hidden'] : []}
                    />
                    <Modal.Header.Title.Content>
                        Log in or register
                    </Modal.Header.Title.Content>
                </Modal.Header.Title.Container>
            </Modal.Header.Container>
            <Modal.Body.Container>
                <Modal.Body.Title.Container>
                    <Modal.Body.Title.Title>
                        {ContentStructure[page].title}
                    </Modal.Body.Title.Title>
                    <Modal.Body.Title.Subtitle>
                        {ContentStructure[page].subtitle}
                    </Modal.Body.Title.Subtitle>
                </Modal.Body.Title.Container>
                {ContentStructure[page].elements.map(
                    (element) => elements[element]
                )}
            </Modal.Body.Container>
            <Modal.Footer.Container
                modifiers={page === 'openID4VC' ? [] : ['hidden']}
            >
                <Modal.Footer.Text>Don't have the Neoke app?</Modal.Footer.Text>
                <Modal.Footer.Link>Download it now</Modal.Footer.Link>
                <TODO t="Download the app" />
            </Modal.Footer.Container>
        </Modal.Container>
    );
};

export default Component;

import { useCallback, useState } from 'react';

export const useHandleLoading = (initialWip = '') => {
    const [wip, setWIP] = useState(initialWip);

    const handleLoading = useCallback(
        ({ loading, method, callback, error, alert }) => {
            if (loading) {
                if (!wip) setWIP(method);
            } else {
                if (wip === method) {
                    setWIP('');
                    if (callback) callback();
                    if (alert && !error) window.alert(alert);
                }
            }
        },
        [wip]
    );

    return { wip, handleLoading };
};

import { Container, Form } from 'react-bootstrap';
import { Main as Provider } from 'component/context';
const Component = () => {
    const { SAMLWebPubSubHook } = Provider.useContext();

    const renderAuthenticateEmail = () => (
        <div className="d-grid gap-2">
            <Form className="d-grid gap-2">
                <Form.Control
                    type="email"
                    placeholder="email"
                    disabled={SAMLWebPubSubHook.RequestAuth.triggered}
                    onChange={({ target: { value } }) =>
                        SAMLWebPubSubHook.RequestAuth.setArgs({
                            identifier: value.trim(),
                        })
                    }
                />
            </Form>
            <div className="d-grid gap-2">
                {SAMLWebPubSubHook.RequestAuth.control}
            </div>
        </div>
    );

    return (
        <Container>
            <div className="d-grid gap-2">
                <>{renderAuthenticateEmail()}</>
            </div>
        </Container>
    );
};

export default Component;

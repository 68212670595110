import { ArrowLeft } from 'react-bootstrap-icons';

import S from 'theme/structure';

const { Container, Body, Footer, Content } = S.Panel;

const Component = ({ header, footer, handleBack, children, ...props }) => (
    <Container {...props}>
        <Body.Container>
            <Body.Title.Container
                modifiers={!header && !handleBack ? ['empty'] : []}
            >
                <Body.Title.Row>
                    <Body.Title.Col subclasses={['left']} xs="1">
                        {handleBack && <ArrowLeft onClick={handleBack} />}
                    </Body.Title.Col>
                    <Body.Title.Col subclasses={['center']}>
                        {header}
                    </Body.Title.Col>
                    <Body.Title.Col
                        subclasses={['right']}
                        xs="1"
                    ></Body.Title.Col>
                </Body.Title.Row>
            </Body.Title.Container>
            {children && <Content>{children}</Content>}
        </Body.Container>
        <Footer.Container modifiers={!footer ? ['empty'] : []}>
            {footer}
        </Footer.Container>
    </Container>
);

export default Component;

import React, {
    createContext,
    useContext as useReactContext,
    useState,
    useEffect,
    useCallback,
} from 'react';

import { User } from 'component/api';
import * as Utils from './Utils';

export const Context = createContext();

export const Provider = ({ children }) => {
    const [user, setUser] = useState(Utils.storage.local.json.read('user'));

    const [error, setError] = useState(null);
    const [apiResult, setApiResult] = useState(null);

    const loadings = {
        oauthSignIn: useState(false),
        signIn: useState(false),
        signUp: useState(false),
        signOut: useState(false),
        changePassword: useState(false),
        deleteAccount: useState(false),
    };

    useEffect(() => {
        const parameters = new URLSearchParams(window.location.search);
        const r = parameters.get('session_id');

        if (r) {
            if (user) {
                window.location.href = window.location.origin;
            } else {
                User.recoverSession(r).then(({ value }) => setUser(value));
            }
        }
    }, [user]);

    useEffect(() => {
        if (
            JSON.stringify(user) !==
            JSON.stringify(Utils.storage.local.json.read('user'))
        ) {
            setUser(
                Utils.storage.local.json.store('user', user, [
                    'username',
                    'profile',
                    'preferences',
                ])
            );
        }
    }, [user]);

    const op = Utils.operationCaller(setError, setApiResult);

    // TODO modify this
    const logUser = useCallback(
        async ({ user, preferences }) =>
            User.signIn({
                username: user.username,
                ...(user.password
                    ? { password: user.password }
                    : { otp: user.otp }),
            }).then((result) => {
                if (result.status === 200) {
                    if (preferences) {
                        user.preferences = preferences;
                    }

                    setUser(user);

                    if (window.location.href !== window.location.origin) {
                        window.location.href = window.location.origin;
                    }
                } else {
                    window.alert('Error logging in');
                    window.location.reload();
                }
            }),
        [setUser]
    );

    const logoutUser = useCallback(() => {
        setUser(null);
        if (window.location.href !== window.location.origin) {
            window.location.href = window.location.origin;
        }
    }, [setUser]);

    const operations = {
        oauthSignIn: (accessToken) =>
            op({
                state: loadings['oauthSignIn'],
                operation: () => User.oauthSignIn(accessToken),
                callbacks: {
                    200: async ({ value }) => {
                        // TODO remove this...
                        if (value.created) {
                            await User.oauthSignInSecondary(accessToken);
                        }
                        await logUser({ user: value });
                    },
                },
            }),
        signIn: ({ username, password }) =>
            op({
                state: loadings['signIn'],
                operation: () => User.signIn({ username, password }),
                callbacks: {
                    200: ({ value }) => setUser(value),
                },
            }),
        signUp: ({ username, password }) =>
            op({
                state: loadings['signUp'],
                operation: () => User.signUp(username, password),
                callbacks: {
                    200: ({ value }) => setUser(value),
                },
            }),
        signOut: () =>
            op({
                state: loadings['signOut'],
                operation: () => User.signOut(),
                callbacks: {
                    200: logoutUser,
                    403: logoutUser,
                },
            }),
        changePassword: (newPassword) =>
            op({
                state: loadings['changePassword'],
                operation: () => User.changePassword(newPassword),
                callbacks: {
                    200: ({ value: { after } }) => setUser(after),
                },
            }),
        deleteAccount: () =>
            op({
                state: loadings['deleteAccount'],
                operation: () => User.deleteAccount(),
                defaultCallback: logoutUser,
            }),
    };

    return (
        <Context.Provider
            value={{
                logUser,
                user,
                userError: error,
                userApiResult: apiResult,
                ...Utils.operationsToJSON(operations),
                ...Utils.loadingsToJSON(loadings),
            }}
        >
            {children}
        </Context.Provider>
    );
};

export const useContext = () => {
    const context = useReactContext(Context);
    if (!context) {
        throw new Error('useContext should be used inside Provider');
    }
    return context;
};

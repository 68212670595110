import './main.scss';
import './main.css';

import config from 'utils/config';

const loadThemeDynamic = () => {
    const css = localStorage.getItem('css');
    if (css) {
        const style = document.createElement('style');
        style.innerHTML = css;

        document.head.appendChild(style);
    }

    return css !== null;
};

const loadThemeMode = () => {
    switch (config.mode) {
        case 'dev':
            require('./dev.css');
            break;
        default:
            break;
    }
};

const loadTheme = () => {
    if (!loadThemeDynamic()) loadThemeMode();
};

export default loadTheme;

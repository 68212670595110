import { Main as Router } from 'component/router';
import { Main as Provider } from 'component/context';

const Main = () => (
    <Provider.Provider>
        <Router />
    </Provider.Provider>
);

export default Main;

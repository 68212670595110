import * as Utils from './Utils';

const BASE_URL = `${process.env.REACT_APP_API}/public/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_BACKEND_SERVICE}/user`;

export const obtainLoginVP = () =>
    Utils.fetcher(`${BASE_URL}/loginwithvc`, { method: 'GET' });

export const readAuthenticatedWithVC = (id) =>
    Utils.fetcher(`${BASE_URL}/readauthenticatedwithvc/${id}`, {
        method: 'GET',
    });

export const exportVC = (preferences) =>
    Utils.fetcher(`${BASE_URL}/exportvc`, {
        method: 'POST',
        body: JSON.stringify({ preferences }),
    });

import { Outlet } from 'react-router-dom';

import config from 'utils/config';

import S from 'theme/structure';
import { useEffect, useState } from 'react';

const {
    Body: { Container, Background },
} = S;

let backgroundImage = '';
switch (config.mode) {
    default:
        backgroundImage = '';
}

const style = {
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : '',
};

const Component = () => {
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        if (counter === 5) {
            const action =
                localStorage.getItem('showDebug') === 'show'
                    ? 'disable'
                    : 'enable';

            action === 'enable'
                ? localStorage.setItem('showDebug', 'show')
                : localStorage.removeItem('showDebug');

            setCounter(0);

            window.alert('Debug mode ' + action + 'd');
        }
    }, [counter]);

    return (
        <Container id="body">
            <Background style={style} onClick={() => setCounter(counter + 1)} />
            <Outlet />
        </Container>
    );
};

export default Component;

import * as Utils from './Utils';

const BASE_URL = `${process.env.REACT_APP_API}/public/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_BACKEND_SERVICE}/user`;

// TODO read status code
export const recoverSession = (r) =>
    Utils.fetcher(`${BASE_URL}/recover?session_id=${r}`, { method: 'GET' });

export const oauthSignIn = (accessToken) =>
    Utils.fetcher(`${BASE_URL}/oauthsignin`, {
        method: 'POST',
        body: JSON.stringify({ accessToken, provider: 'google' }),
    });

// TODO remove this...
export const oauthSignInSecondary = (accessToken) =>
    Utils.fetcher(
        `${process.env.REACT_APP_API}/public/${process.env.REACT_APP_API_VERSION}/uis/user/oauthsignin`,
        {
            method: 'POST',
            body: JSON.stringify({ accessToken, provider: 'google' }),
        }
    );

export const obtainVPLoginOpenID4VC = () =>
    Utils.fetcher(`${BASE_URL}/loginwithvc`, { method: 'GET' });

export const signIn = ({ username, password, otp }) =>
    Utils.fetcher(`${BASE_URL}/signin`, {
        method: 'POST',
        body: JSON.stringify({ username, password, otp }),
    });

export const signUp = (username, password) =>
    Utils.fetcher(`${BASE_URL}/signup`, {
        method: 'POST',
        body: JSON.stringify({ username, password }),
    });

export const signOut = () =>
    Utils.fetcher(
        `${BASE_URL}/signout`,
        {
            method: 'POST',
        },
        false
    );

export const changePassword = (newPassword) =>
    Utils.fetcher(`${BASE_URL}/changepassword`, {
        method: 'PUT',
        body: JSON.stringify({ newPassword }),
    });

export const deleteAccount = () =>
    Utils.fetcher(`${BASE_URL}/deleteaccount`, {
        method: 'DELETE',
    });

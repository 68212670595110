import { useCallback, useEffect, useState } from 'react';

import { Main as Provider } from 'component/context';
import { useHandleLoading } from 'component/hook';

import { Container, Button, Form } from 'react-bootstrap';

const Component = () => {
    const {
        user,
        signOut,
        changePassword,
        deleteAccount,
        loadingSignOut,
        loadingChangePassword,
        loadingDeleteAccount,
        userError,
        OpenID4VCHook,
    } = Provider.useContext();

    const [newPassword, setNewPassword] = useState('');
    const { handleLoading } = useHandleLoading();

    const resetStates = useCallback(() => {
        if (newPassword !== '') setNewPassword('');
    }, [newPassword]);

    useEffect(
        () =>
            handleLoading({
                loading: loadingSignOut,
                method: 'signOut',
                callback: resetStates,
                error: userError,
            }),
        [handleLoading, resetStates, loadingSignOut, userError]
    );

    useEffect(
        () =>
            handleLoading({
                loading: loadingChangePassword,
                method: 'changePassword',
                callback: resetStates,
                error: userError,
                alert: 'password changed',
            }),
        [handleLoading, resetStates, loadingChangePassword, userError]
    );

    useEffect(
        () =>
            handleLoading({
                loading: loadingDeleteAccount,
                method: 'deleteAccount',
                callback: resetStates,
                error: userError,
            }),
        [handleLoading, resetStates, loadingDeleteAccount, userError]
    );

    const renderChangePassword = () => (
        <>
            <Form className="d-grid gap-2">
                <Form.Label>Change password</Form.Label>
                <Form.Control
                    type="password"
                    value={newPassword}
                    placeholder="New password"
                    onChange={({ target: { value } }) => setNewPassword(value)}
                />
                <Button
                    variant="secondary"
                    disabled={!newPassword || loadingChangePassword}
                    loading={loadingChangePassword.toString()}
                    onClick={() => changePassword(newPassword)}
                >
                    Change password
                </Button>
            </Form>
            <hr />
        </>
    );

    return (
        <Container>
            <p>Hi, {user.username}</p>
            <hr />
            <div className="d-grid gap-2">
                {renderChangePassword()}
                <div className="d-grid gap-2">
                    {OpenID4VCHook.RamID.control}
                    <Button
                        variant="primary"
                        disabled={loadingSignOut}
                        loading={loadingSignOut.toString()}
                        onClick={signOut}
                    >
                        Sign out
                    </Button>
                    <Button
                        variant="danger"
                        disabled={loadingDeleteAccount}
                        loading={loadingDeleteAccount.toString()}
                        onClick={deleteAccount}
                    >
                        Delete account
                    </Button>
                </div>
            </div>
        </Container>
    );
};

export default Component;

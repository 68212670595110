import { Header, Body } from './component';

import S from 'theme/structure';

const { Layout } = S;

const Component = () => (
    <Layout>
        <Header />
        <Body />
    </Layout>
);

export default Component;

import QRCode from 'qrcode.react';
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Modal,
    Row,
    Spinner,
} from 'react-bootstrap';
import { ArrowLeft } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

import { BEM } from 'utils/bem';

const structure = {
    Layout: new BEM('layout').component('div'),
    Header: new BEM('header').structure({
        Container: Container,
        Row,
        Col,
    }),
    Body: new BEM('body').structure({
        $Container: 'div',
        Background: 'div',
    }),
    Container: new BEM('container').structure({
        Page: Container,
        Section: Row,
        SubSection: Col,
    }),
    NavBar: new BEM('navbar').structure({
        $Container: 'ul',
        Item: {
            $Container: 'li',
            Link,
        },
    }),
    Panel: new BEM('panel').structure({
        $Container: Card,
        Body: {
            $Container: Card.Body,
            Title: {
                $Container: Card.Title,
                Row,
                Col,
            },
        },
        Content: 'div',
        Footer: {
            $Container: Card.Footer,
            Text: 'div',
            Link: 'div',
        },
    }),
    SessionInfo: new BEM('session-info').structure({
        $Container: 'div',
        Label: 'p',
        Value: 'pre',
    }),
    Form: new BEM('form').structure({
        $Container: Form,
        Buttons: {
            $Container: Row,
            Button,
        },
        Page: 'div',
        Row: {
            $Container: Row,
            Content: {
                $Container: Form.Group,
                Label: Form.Label,
                Feedback: Form.Control.Feedback,
            },
        },
        Section: {
            $Container: 'div',
            Title: 'div',
        },
    }),
    OpenID4VC: new BEM('openid4vc').structure({
        $Container: 'div',
        QR: {
            $Container: 'div',
            Title: 'div',
            QRCode,
            Copy: 'div',
            Spinner,
            Description: 'div',
        },
        Error: 'div',
        Success: 'div',
        Info: {
            $Container: 'div',
            Title: 'div',
            Button,
        },
    }),
    WebPubSub: new BEM('webpubsub').structure({
        $Container: 'div',
        Error: 'div',
        Success: 'div',
        Info: {
            $Container: 'div',
            Title: 'div',
            Button,
        },
        Spinner,
    }),
    Modal: new BEM('modal').structure({
        $Container: Modal,
        Header: {
            $Container: Modal.Header,
            Title: {
                Container: Modal.Title,
                Back: ArrowLeft,
                Content: 'span',
            },
        },
        Body: {
            $Container: Modal.Body,
            Title: {
                $Container: 'div',
                Title: 'div',
                Subtitle: 'div',
            },
            Section: {
                $Container: 'div',
                Title: 'div',
            },
        },
        Footer: {
            $Container: Modal.Footer,
            Text: 'div',
            Link: 'div',
        },
    }),
    Login: new BEM('login').structure({
        Button,
    }),
    Divider: new BEM('divider').structure({
        $Container: 'div',
        Line: 'span',
        Text: 'span',
    }),
    Google: new BEM('google').structure({
        Button,
    }),
    TODO: new BEM('todo').structure({
        $Container: 'div',
        Who: 'div',
        Title: 'div',
        Description: 'div',
        Hide: Button,
    }),
};

export default structure;

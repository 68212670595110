import { Main as Layout } from 'component/layout';
import { Account, UnknownMode } from 'component/page';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import config from 'utils/config';

const RouterWrapper = ({ children }) => (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Layout />}>
                {children}
            </Route>
        </Routes>
    </BrowserRouter>
);

const RouterDev = () => (
    <RouterWrapper>
        <Route index element={<Account.Login />} />
        <Route path="login" element={<Account.Login />} />
    </RouterWrapper>
);

const RouterDefault = () => (
    <RouterWrapper>
        <Route index element={<UnknownMode />} />
    </RouterWrapper>
);

const Component = () => {
    switch (config.mode) {
        case config.MODES.dev:
            return <RouterDev />;

        default:
            return <RouterDefault />;
    }
};

export default Component;

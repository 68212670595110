import * as Utils from './Utils';

const BASE_URL = `${process.env.REACT_APP_API}/public/${process.env.REACT_APP_API_VERSION}/ui/saml`;

export const requestAuth = ({ samlRequest, identifier }) =>
    Utils.fetcher(`${BASE_URL}/requestAuth`, {
        method: 'POST',
        headers: {
            SAMLRequest: samlRequest,
        },
        body: JSON.stringify({ identifier }),
    });

export const login = ({ samlRequest, accessToken }) =>
    Utils.fetcher(`${BASE_URL}/login`, {
        headers: {
            SAMLRequest: samlRequest,
            Authorization: `Bearer ${accessToken}`,
        },
    });

export const postSAMLResponse = async ({
    entityEndpoint,
    SAMLResponse,
    RelayState,
}) => {
    try {
        await fetch(entityEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                SAMLResponse,
                RelayState,
                redirect: false,
            }),
        })
            .then((response) => response.json())
            .then(({ url }) => {
                window.location.href = url;
            });
    } catch (error) {
        console.error('Error sending SAML Response:', error);
        throw error;
    }
};

import { useGoogleLogin } from '@react-oauth/google';
import { useCallback, useEffect, useState } from 'react';

import { Main as Provider } from 'component/context';

import Logo from 'images/google-logo.svg';

import S from 'theme/structure';

const { Google } = S;

export const useGoogleLoginButton = () => {
    const { user, oauthSignIn } = Provider.useContext();
    const [googleToken, setGoogleToken] = useState(undefined);
    const [wip, setWIP] = useState(false);
    const [triggered, setTriggered] = useState(false);

    useEffect(() => {
        if (wip) {
            if (user) setWIP(false);
        } else if (googleToken && !user) {
            setWIP(true);
            oauthSignIn(googleToken.access_token);
        }
    }, [googleToken, user, wip, oauthSignIn]);

    const handleError = (errorResponse) => {
        console.error('Google login failed', errorResponse);
    };

    // Note: There is a warning in the console related to the Cross Origin Opener Policy
    // In theory, it should be fixed by adding the Cross-Origin-Opener-Policy header
    // and this is already done in the index.html file, but it doesn't seem to work
    // However, the login process works fine and I've spent 3 hours trying to find
    // a solution, so it's not a big deal for now..
    //
    // See:
    // https://developers.google.com/identity/gsi/web/guides/get-google-api-clientid#cross_origin_opener_policy
    // https://github.com/MomenSherif/react-oauth/issues/295
    // https://stackoverflow.com/questions/76446840/cross-origin-opener-policy-policy-would-block-the-window-closed-call-error-while
    const login = useGoogleLogin({
        onSuccess: setGoogleToken,
        onFailure: handleError,
        flow: 'implicit',
    });

    const handleLogin = useCallback(() => {
        setTriggered(true);
        login();
    }, [login]);

    const control = (
        <Google.Button
            variant="outline-secondary"
            className="button__rounded"
            disabled={triggered}
            onClick={handleLogin}
        >
            <img src={Logo} alt="Logo" />
            Continue with Google
        </Google.Button>
    );

    return { control, triggered };
};

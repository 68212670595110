import React, { createContext, useContext as useReactContext } from 'react';

import { useOpenID4VC } from 'component/hook';
import { OpenID4VC } from 'component/api';

import { Context as UserContext } from './User';

export const Context = createContext();

export const Provider = ({ children }) => {
    const { logUser } = useReactContext(UserContext);

    return (
        <Context.Provider
            value={{
                OpenID4VCHook: {
                    Login: useOpenID4VC({
                        hookId: 'Login',
                        operation: OpenID4VC.obtainLoginVP,
                        actions: {
                            'business/user': {
                                authenticatedWithVC: {
                                    trigger: logUser,
                                    final: true,
                                },
                            },
                        },
                        presentationVerifiedIsFinal: false,
                        triggerText: 'Continue with Verifiable Credential',
                        successMessage: '',
                        qrCodeTitle: '',
                        waitingActionTitle: '',
                    }),
                    ExportVC: useOpenID4VC({
                        hookId: 'ExportVC',
                        operation: OpenID4VC.exportVC,
                        qrCodeTitle: '',
                    }),
                },
            }}
        >
            {children}
        </Context.Provider>
    );
};

export const useContext = () => {
    const context = useReactContext(Context);
    if (!context) {
        throw new Error('useContext should be used inside Provider');
    }
    return context;
};

import { Account, Panel, SAML } from 'component/common';
import { Main as Provider } from 'component/context';

import S from 'theme/structure';

const {
    Container: { Page, Section, SubSection },
    SessionInfo,
} = S;

const Component = () => {
    const { user, userApiResult } = Provider.useContext();

    return (
        <Page modifiers={['full']}>
            <Section>
                <SubSection>
                    <Panel header="Account">
                        {user ? <Account.Profile /> : <Account.SuSi />}
                    </Panel>
                </SubSection>
                <SubSection>
                    <Panel header="Session info explorer">
                        <SessionInfo.Container>
                            <SessionInfo.Label>User:</SessionInfo.Label>
                            <SessionInfo.Value>
                                {JSON.stringify(user, null, 2)}
                            </SessionInfo.Value>
                            <SessionInfo.Label>
                                User API result:
                            </SessionInfo.Label>
                            <SessionInfo.Value modifiers={['scroll']}>
                                {JSON.stringify(userApiResult, null, 2)}
                            </SessionInfo.Value>
                        </SessionInfo.Container>
                    </Panel>
                </SubSection>
            </Section>
            {!user && (
                <Section>
                    <SubSection>
                        <Panel header="Social login (SAML)">
                            <SAML.Login />
                        </Panel>
                    </SubSection>
                </Section>
            )}
        </Page>
    );
};

export default Component;

import { WebPubSub } from 'component/api';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import S from 'theme/structure';

export const useOpenID4VC = ({
    operation,
    hookId,
    infoTitle = '',
    waitingActionTitle = 'Obtaining the VC...',
    waitingProviderTitle = '',
    triggerText = 'Obtain the VC',
    qrCodeTitle = 'Scan the QR to obtain the VC',
    grCodeDescription = 'Simply scan the QR code using your phone.',
    successMessage = 'VC obtained!',
    actions = {},
    presentationVerifiedIsFinal = true,
    issuanceSuccessfulIsFinal = true,
}) => {
    const args = useRef([]);
    const websocket = useRef(null);
    const [triggered, setTriggered] = useState(false);
    const [link, setLink] = useState(null);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null);
    const [error, setError] = useState(null);
    const [completed, setCompleted] = useState(false);

    const getStorageKey = useCallback(
        () => `hook${hookId}`.toLowerCase(),
        [hookId]
    );

    const stopDiscovery = useCallback(() => {
        if (websocket.current) {
            websocket.current.close();
            websocket.current = null;
        }

        localStorage.removeItem(getStorageKey());
    }, [getStorageKey]);

    const abort = useCallback(() => {
        stopDiscovery();
        setLink(null);
        setLoading(false);
        setStatus(null);
        setError(null);
        setCompleted(false);
        setTriggered(false);
    }, [stopDiscovery]);

    const readWebPubSubMessages = useCallback(async () => {
        const storageKey = getStorageKey();
        const id = localStorage.getItem(storageKey);
        if (id) {
            let pop, queued;
            do {
                pop = await WebPubSub.pop(id);
                const { message } = pop.value;
                queued = pop.queued;

                if (message) {
                    const { origin, type, payload } = message;
                    if (actions[origin] && actions[origin][type]) {
                        const { final, trigger } = actions[origin][type];
                        if (final) stopDiscovery();
                        trigger(payload);
                        if (final) setCompleted(true);
                    } else if (origin === 'business/EntraVerifiedID') {
                        setStatus(payload.data.status);
                    } else {
                        console.log('Unsuported message', origin, type);
                    }
                }
            } while (queued > 0);
        }
    }, [getStorageKey, actions, stopDiscovery]);

    useEffect(() => {
        if (completed) stopDiscovery();
    }, [completed, stopDiscovery]);

    useEffect(() => {
        return () => {
            abort();
        };
    }, [abort]);

    useEffect(() => {
        if (status) {
            switch (status) {
                case 'request_retrieved':
                    setLink(null);
                    break;
                case 'issuance_successful':
                    if (issuanceSuccessfulIsFinal) setCompleted(true);
                    break;
                case 'presentation_verified':
                    if (presentationVerifiedIsFinal) setCompleted(true);
                    break;

                default:
                    setCompleted(true);
            }
        }
    }, [status, issuanceSuccessfulIsFinal, presentationVerifiedIsFinal]);

    useEffect(() => {
        const storageKey = getStorageKey();
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') readWebPubSubMessages();
        };

        document.addEventListener(storageKey, handleVisibilityChange);

        return () => {
            document.removeEventListener(
                getStorageKey(),
                handleVisibilityChange
            );
        };
    }, [readWebPubSubMessages, getStorageKey]);

    const performOperation = useCallback(() => {
        setLoading(true);
        setStatus(null);
        setError(null);
        setCompleted(false);
        setTriggered(true);

        const callbacks = {
            200: ({
                value: { webPubSubAccessToken, openid4vc, neokeauthvc, id },
            }) => {
                if (webPubSubAccessToken) {
                    const storageKey = getStorageKey();
                    localStorage.removeItem(storageKey);
                    localStorage.setItem(storageKey, id);

                    websocket.current = new WebSocket(webPubSubAccessToken.url);
                    websocket.current.onmessage = () => readWebPubSubMessages();
                }

                setLink(neokeauthvc ?? openid4vc.url);
            },
        };

        return operation(...args.current)
            .then((result) => {
                if (result?.status) {
                    const c = callbacks[result.status];
                    return c ? c(result) : result;
                }
                return result;
            })
            .then(() => setLoading(false))
            .catch((err) => {
                setError(err);
                setLoading(false);
            });
    }, [operation, getStorageKey, readWebPubSubMessages]);

    const loadingTitle = loading ? waitingProviderTitle : waitingActionTitle;

    const controls = {
        link: useMemo(
            () => (
                <>
                    <S.OpenID4VC.QR.Container>
                        <S.OpenID4VC.QR.Title
                            modifiers={qrCodeTitle ? [] : ['hidden']}
                        >
                            {qrCodeTitle}
                        </S.OpenID4VC.QR.Title>
                        <S.OpenID4VC.QR.QRCode value={link} size={216} />
                        <S.OpenID4VC.QR.Description>
                            {grCodeDescription}
                        </S.OpenID4VC.QR.Description>
                    </S.OpenID4VC.QR.Container>
                </>
            ),
            [link, qrCodeTitle, grCodeDescription]
        ),
        loading: useMemo(
            () => (
                <>
                    <S.OpenID4VC.QR.Container>
                        <S.OpenID4VC.QR.Title
                            modifiers={loadingTitle ? [] : ['hidden']}
                        >
                            {loadingTitle}
                        </S.OpenID4VC.QR.Title>
                        <S.OpenID4VC.QR.Spinner animation="border" />
                    </S.OpenID4VC.QR.Container>
                </>
            ),
            [loadingTitle]
        ),
        error: useMemo(
            () => (
                <S.OpenID4VC.Error>
                    Error obtaining the VC: {error}
                </S.OpenID4VC.Error>
            ),
            [error]
        ),
        success: useMemo(
            () => <S.OpenID4VC.Success>{successMessage}</S.OpenID4VC.Success>,
            [successMessage]
        ),
        button: useMemo(
            () => (
                <S.OpenID4VC.Info.Container className="centered">
                    {infoTitle && (
                        <S.OpenID4VC.Info.Title>
                            {infoTitle}
                        </S.OpenID4VC.Info.Title>
                    )}
                    <S.OpenID4VC.Info.Button
                        className="button__rounded"
                        variant="primary"
                        disabled={loading}
                        loading={loading.toString()}
                        onClick={performOperation}
                        size="sm"
                    >
                        {triggerText}
                    </S.OpenID4VC.Info.Button>
                </S.OpenID4VC.Info.Container>
            ),
            [loading, performOperation, triggerText, infoTitle]
        ),
    };

    let control;
    if (completed) {
        control = controls.success;
    } else if (error) {
        control = controls.error;
    } else if (link) {
        control = controls.link;
    } else if (status) {
        control = controls.loading;
    } else if (loading) {
        control = controls.loading;
    } else {
        control = controls.button;
    }

    return {
        control,
        abort,
        triggered,
        completed,
        performOperation,
        setArgs: (a) => (args.current = a),
    };
};

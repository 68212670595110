export const storage = {
    local: {
        json: {
            read: (key) => JSON.parse(localStorage.getItem(key)) || null,
            store: (key, value, keys) => {
                if (value) {
                    const v = {};
                    for (const k of keys)
                        if (value.hasOwnProperty(k)) v[k] = value[k];
                    localStorage.setItem(key, JSON.stringify(v));
                } else {
                    localStorage.removeItem(key);
                }
                return JSON.parse(localStorage.getItem(key)) || null;
            },
        },
    },
};

export const loadingsToJSON = (loadings) => {
    const json = {};
    Object.keys(loadings).forEach((key) => {
        json[`loading${key.charAt(0).toUpperCase()}${key.substring(1)}`] =
            loadings[key][0];
    });
    return json;
};

export const operationsToJSON = (operations) => {
    const json = {};
    Object.keys(operations).forEach((key) => {
        json[key] = operations[key];
    });
    return json;
};

export const operationCaller =
    (setError, setApiResult) =>
    ({
        state,
        operation,
        callbacks,
        defaultCallback = ({ error }) => setError(error),
    }) =>
        performOperation({
            setError,
            loading: state[0],
            setLoading: state[1],
            operation,
            callbacks,
            defaultCallback,
            setApiResult,
        });

export const performOperation = ({
    setError,
    setLoading,
    loading,
    operation,
    setApiResult,
    callbacks = {},
    defaultCallback = (result) => result,
}) => {
    if (loading) return;
    setLoading(true);
    setError(null);
    setApiResult(null);

    operation()
        .then((result) => {
            setApiResult(result);
            if (result?.status) {
                const c = callbacks[result.status];
                return c ? c(result) : defaultCallback(result);
            }
            return defaultCallback(result);
        })
        .then(() => setLoading(false))
        .catch((err) => {
            setError(err);
            setLoading(false);
        });
};

import S from 'theme/structure';

const { Container, Line, Text } = S.Divider;

const Component = ({ children, ...props }) => (
    <Container {...props}>
        <Line />
        <Text>{children}</Text>
        <Line />
    </Container>
);

export default Component;
